<template>
  <div class="buying-power__form-wrapper">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="buying-power__form" @submit.prevent="handleSubmit(onSubmit)">
        <header>
          <NavbarScrollable
            :options="[
              {
                key: 'car',
                value: $t('buying_power.form.vehcile_type.car'),
              },
              {
                key: 'motorbike',
                value: $t('buying_power.form.vehcile_type.motorbike'),
              },
            ]"
            :selection="formState.vehicleType"
            @submit="(value) => (formState.vehicleType = value)"
          />
        </header>

        <ValidationProvider
          v-if="formState.vehicleType !== 'motorbike'"
          v-slot="{ touched, errors }"
          name="category"
          :skip-if-empty="false"
          rules="required"
        >
          <RadioGroup
            :value="formState.category"
            name="category"
            :options="[
              {
                label: $t('buying_power.form.category.vo'),
                value: 'VO',
              },
              {
                label: $t('buying_power.form.category.vn'),
                value: 'VN',
              },
              {
                label: $t('buying_power.form.category.km0'),
                value: 'Km0',
              },
            ]"
            :error="touched ? errors[0] : null"
            @input="(value) => (formState.category = value)"
          />
        </ValidationProvider>

        <div class="buying-power__form__content">
          <ValidationProvider
            v-slot="{ touched, errors }"
            :name="$t('buying_power.form.monthly_income.label')"
            :skip-if-empty="false"
            rules="numeric|required|min_value:1"
          >
            <Input
              v-model="formState.monthlyIncome"
              :label="$t('buying_power.form.monthly_income.label')"
              name="monthly_income"
              :min="1"
              type="number"
              :placeholder="$t('buying_power.form.monthly_income.placeholder')"
              :error="touched ? errors[0] : null"
              required
            />
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ touched, errors }"
            :name="$t('buying_power.form.monthly_credits_amount.label')"
            :skip-if-empty="true"
            :rules="`numeric|max_value:${formState?.monthlyIncome || 0}`"
          >
            <Input
              v-model="formState.monthlyCreditsAmount"
              :label="$t('buying_power.form.monthly_credits_amount.label')"
              name="monthly_credits_amount"
              :min="0"
              :max="Number(formState.monthlyIncome)"
              type="number"
              :placeholder="$t('buying_power.form.monthly_credits_amount.placeholder')"
              :hint="$t('buying_power.form.monthly_credits_amount.hint')"
              :error="touched ? errors[0] : null"
            />
          </ValidationProvider>
        </div>

        <button v-show="false" ref="submit" />
      </form>
    </ValidationObserver>

    <ModalsSoftRegistrationForm
      :open="showSoftRegistrationForm"
      @submit="(state) => onSubmitSoftRegistration(state)"
      @close="() => onCancelSoftRegistration()"
    />

    <footer>
      <Button
        type-theme="primary-light"
        :state="submitButtonState"
        :loading="loading"
        data-cy="submit-form"
        @click="() => onSubmit()"
      >
        <span>
          {{ $t('buying_power.form.submit') }}
        </span>
      </Button>
    </footer>
  </div>
</template>

<script>
// Dependencies
import { mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// Components
import Button from '@/components/Button.vue'
import NavbarScrollable from '@/components/NavbarScrollable.vue'
import Input from '@/components/Input.vue'
import RadioGroup from '@/components/RadioGroup.vue'
import ModalsSoftRegistrationForm from '@/components/modals/SoftRegistrationForm.vue'

export default {
  name: 'PartialBuyingPowerForm',
  components: {
    Button,
    NavbarScrollable,
    Input,
    RadioGroup,
    ModalsSoftRegistrationForm,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    redirectToResultsPage: {
      type: Boolean,
      required: false,
      default: true,
    },
    state: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      loading: false,
      showSoftRegistrationForm: false,
      formState: {
        vehicleType: 'car',
        category: 'VO',
        monthlyIncome: '',
        monthlyCreditsAmount: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      loggedIn: 'auth/loggedIn',
      softLoggedIn: 'auth/softLoggedIn',
      softUser: 'auth/softUser',
      currentUser: 'auth/currentUser',
    }),
    submitButtonState() {
      return !this.loading &&
        this.formState.monthlyIncome &&
        Number(this.formState.monthlyCreditsAmount) <= Number(this.formState.monthlyIncome)
        ? 'enabled'
        : 'disabled'
    },
  },
  methods: {
    onSubmit() {
      this.loading = true

      if (!this.loggedIn && !this.softLoggedIn) {
        this.showSoftRegistrationForm = true
      } else {
        this.completeTransaction()
      }
    },
    onSubmitSoftRegistration(success) {
      if (success) {
        this.completeTransaction()
      } else {
        this.$emit('submit', false)
      }
    },
    onCancelSoftRegistration() {
      this.loading = false
      this.showSoftRegistrationForm = false
    },
    completeTransaction() {
      sessionStorage.setItem('buying_power_results_notifications_flag', 'ready')

      this.$emit('submit', {
        ...this.formState,
      })

      if (this.redirectToResultsPage) {
        this.$router.push(
          this.localePath({
            name: 'buying-power-results',
            query: {
              ...this.formState,
              category:
                this.formState.vehicleType === 'motorbike' ? 'motorbike' : this.formState.category,
              name: this.loggedIn ? this.currentUser.name : this.softUser.name,
              lastName: this.loggedIn ? this.currentUser.lastName : this.softUser.lastName,
              email: this.loggedIn ? this.currentUser.email : this.softUser.email,
              commercialInformationConsented: this.loggedIn
                ? this.currentUser.commercialInformationConsented
                : this.softUser.commercialInformationConsented,
              termsOfServiceAccepted: this.loggedIn
                ? this.currentUser.gdprConsents.gdprMarca
                : this.softUser.termsOfServiceAccepted,
            },
          }),
        )
      }
    },
  },
  mounted() {
    if (this.state) {
      this.formState = {
        ...this.formState,
        ...this.state,
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.buying-power__form-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 31.875rem;
  padding: 2.5rem;
  border-radius: 0.5rem;
  background-color: $c-white;

  @include size-m {
    padding: 1.5rem;
  }

  .buying-power__form {
    width: 100%;

    :deep(.radio-group__items) {
      margin-bottom: 2rem;
    }

    :deep(.navbar-scrollable) {
      width: 100% !important;

      .swiper-wrapper {
        transform: none !important;
      }

      .swiper-slide {
        width: 50% !important;
      }
    }

    header {
      margin-bottom: 2rem;
    }

    :deep(.input-wrapper) {
      margin-top: 1rem;
    }
  }

  footer {
    margin-top: auto;
    padding-top: 2.5rem;

    @include size-m-up {
      text-align: left;
    }

    @include size-s {
      .button {
        width: 100%;
      }
    }
  }
}
</style>
